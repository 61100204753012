import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ObjectiveState } from '../../interfaces/objectives';

const initialState: ObjectiveState = {
	info: undefined,
};

export const objectiveSlice = createSlice({
	name: 'objective',
	initialState,
	reducers: {
		setObjectiveInfo: (state, action: PayloadAction<ObjectiveState>) => {
			state.info = action.payload.info;
		},
		clearObjectiveInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setObjectiveInfo, clearObjectiveInfo } = objectiveSlice.actions;
export default objectiveSlice.reducer;
