import axios, { AxiosError, AxiosResponse } from 'axios';

import { CustomError } from '../interfaces/common';

const client = axios.create({
	baseURL: `${import.meta.env.VITE_API_BASE_URL}/admin`,
	headers: {
		'Content-Type': 'application/json',
	},
});

client.defaults.headers.common['Content-Type'] = 'application/json';
client.defaults.headers.common.Accept = 'application/json';

client.interceptors.request.use(
	(request) => {
		const token = localStorage.getItem('token');
		if (token) request.headers.Authorization = `Bearer ${token}`;

		return request;
	},
	(error: AxiosError) => Promise.reject(error),
);

client.interceptors.response.use(
	(response: AxiosResponse) => response,
	async (error: AxiosError<CustomError>) => {
		const needRefreshToken = error.response?.status === 440;
		const closeSession = error.response?.status === 401;
		const token = localStorage.getItem('token');

		if (needRefreshToken) {
			const newToken = await client.post('/auth/refresh', { token });
			localStorage.setItem('token', newToken.data.token);
			if (error.config) return client.request(error.config);
		}

		if (closeSession) window.dispatchEvent(new Event('logout'));
		return Promise.reject(error);
	},
);

const { get, post, put, delete: destroy } = client;
export { destroy, get, post, put };
