import { ViewHeadline } from '@mui/icons-material';
import { AppBar, Box, Hidden, IconButton, Toolbar } from '@mui/material';

import { useApp } from '../../../../hooks/useApp';
import { Fullscreen } from '../fullscreen';
import { UserMenu } from '../menu';
import { Shortcut } from '../shortcut';

const Header = () => {
	const { showNav, setShowNav } = useApp();

	return (
		<AppBar className="z-20 flex shadow-m sticky top-0" color="default">
			<Toolbar className="min-h-48 p-0 md:min-h-64">
				<Box className="flex flex-1 pl-16">
					{!showNav && (
						<IconButton className="h-40 w-40 p-0" aria-haspopup="true" size="large">
							<Box className="flex items-center justify-center w-40 h-40">
								<ViewHeadline onClick={() => setShowNav(true)} />
							</Box>
						</IconButton>
					)}

					<Hidden lgDown>
						<Shortcut />
					</Hidden>
				</Box>

				<Box className="flex pr-16 md:pr-0 h-full items-center overflow-x-auto">
					<Fullscreen />
					<UserMenu />
				</Box>
			</Toolbar>
		</AppBar>
	);
};

export { Header };
