import { Alert } from '@mui/material';
import { Box } from '@mui/system';

import { useAlert } from '../../hooks/useAlert';

const CustomAlert = () => {
	const { show, type, message, handleCloseAlert } = useAlert();

	return (
		<Box
			className={`fixed top-[58px] md:top-[74px] right-8 z-9999 transition-opacity duration-300 ${
				show ? 'opacity-100' : 'opacity-0'
			}`}
		>
			<Box className="w-full md:w-fit min-w-xs mx-auto md-mx-0">
				<Alert onClose={handleCloseAlert} severity={type} icon={false}>
					{message}
				</Alert>
			</Box>
		</Box>
	);
};

export { CustomAlert };
