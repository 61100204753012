import { CommonProperties, WithPaginationResponse } from './common';
import { LevelInfo } from './level';

export type CourseType = 'infant' | 'basic';

export const COURSE_TYPE_ALLOWED = {
	INFANT: 'infant',
	BASIC: 'basic',
};

export const coursesTypeMap = new Map([
	[COURSE_TYPE_ALLOWED.INFANT, 'Párvulo'],
	[COURSE_TYPE_ALLOWED.BASIC, 'Básica'],
]);

export interface CourseInfo extends CommonProperties {
	code: string;
	name: string;
	section: string;
	type: CourseType;
	level?: LevelInfo;
}

export interface CreateLevelParams {
	code: string;
	name: string;
	section: string;
	type: CourseType;
	levelID: string;
}

export interface AllCoursesResponse extends WithPaginationResponse {
	courses: CourseInfo[];
}
