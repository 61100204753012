import { SchoolInfo } from '../interfaces/school';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setSchoolInfo } from '../store/school';

const useSchools = () => {
	const dispatch = useAppDispatch();
	const schoolInfo = useAppSelector((state) => state.school.info);

	const setSchoolInfoAction = async (info: SchoolInfo[]) => {
		try {
			dispatch(setSchoolInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const schools = schoolInfo?.filter((school) => school.isActive);

	return { schools, setSchools: setSchoolInfoAction };
};

export { useSchools };
