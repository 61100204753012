import { Box } from '@mui/material';
import { PropsWithChildren } from 'react';

interface BodyPageProps {
	withTabs?: boolean;
}

const BodyPage = ({ withTabs = false, children }: PropsWithChildren<BodyPageProps>) => {
	return (
		<Box
			className={`relative h-full m-20 border-dashed border-4 border-white-500 rounded-lg ${
				withTabs ? 'mt-48 rounded-t-4' : 'rounded-tl-none'
			}`}
		>
			<Box className="p-20 h-full !bg-white">{children}</Box>
		</Box>
	);
};

export { BodyPage };
