import { Box } from '@mui/material';

export const LoadingPage = () => {
	return (
		<div className="flex flex-1 flex-col items-center justify-center p-24">
			<img width="128" src="/images/logo.svg" alt="logo" />

			<Box
				id="spinner"
				sx={{
					'& > div': {
						backgroundColor: 'palette.secondary.main',
					},
				}}
			>
				<div className="bounce1" />
				<div className="bounce2" />
				<div className="bounce3" />
			</Box>
		</div>
	);
};
