import { LOCALE_ALLOWED, localeTypeMap } from '../interfaces/book';
import { CategoryInfo } from '../interfaces/category';
import { Pagination, SelectOptions } from '../interfaces/common';
import { ContentGroupInfo } from '../interfaces/content-group';
import { COURSE_TYPE_ALLOWED, coursesTypeMap } from '../interfaces/course';
import { SEX_ALLOWED, sexMap } from '../interfaces/student';
import { ROLES_ALLOWED, RolesAllowed, rolesMap } from '../interfaces/user';

const CHARACTERS = {
	NUMERIC: '0123456789',
	ALPHA: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz',
	ALPHA_NUMERIC: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
	ALPHA_NUMERIC_SPECIAL: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$',
};

interface RandomPasswordProps {
	length?: number;
	characters?: string;
}

export const randomPassword = ({ length = 5, characters = CHARACTERS.ALPHA_NUMERIC_SPECIAL }: RandomPasswordProps) => {
	let pass = '';

	for (let i = 0; i < length; i++) {
		const random = Math.floor(Math.random() * characters.length);
		pass += characters[random];
	}

	return pass;
};

export const defaultPagination: Pagination = {
	current: 1,
	limit: 10,
};

export const rolesSelect: SelectOptions[] = [
	{ value: ROLES_ALLOWED.SUPER_ADMIN, label: rolesMap.get(ROLES_ALLOWED.SUPER_ADMIN) as string },
	{ value: ROLES_ALLOWED.ADMIN, label: rolesMap.get(ROLES_ALLOWED.ADMIN) as string },
	{ value: ROLES_ALLOWED.SCHOOL_MANAGER, label: rolesMap.get(ROLES_ALLOWED.SCHOOL_MANAGER) as string },
	{ value: ROLES_ALLOWED.TEACHER, label: rolesMap.get(ROLES_ALLOWED.TEACHER) as string },
	{ value: ROLES_ALLOWED.PARENT, label: rolesMap.get(ROLES_ALLOWED.PARENT) as string },
];

export const rolesForSelect = (role: RolesAllowed) => {
	if (role === ROLES_ALLOWED.ADMIN) {
		const index = rolesSelect.findIndex((r) => r.value === ROLES_ALLOWED.ADMIN);
		return rolesSelect.slice(index + 1);
	}
	return rolesSelect;
};

export const sexForSelect: SelectOptions[] = [
	{ value: SEX_ALLOWED.MALE, label: sexMap.get(SEX_ALLOWED.MALE) as string },
	{ value: SEX_ALLOWED.FEMALE, label: sexMap.get(SEX_ALLOWED.FEMALE) as string },
	{ value: SEX_ALLOWED.OTHER, label: sexMap.get(SEX_ALLOWED.OTHER) as string },
];

export const courseTypeForSelect: SelectOptions[] = [
	{ value: COURSE_TYPE_ALLOWED.INFANT, label: coursesTypeMap.get(COURSE_TYPE_ALLOWED.INFANT) as string },
	{ value: COURSE_TYPE_ALLOWED.BASIC, label: coursesTypeMap.get(COURSE_TYPE_ALLOWED.BASIC) as string },
];

export const localeForSelect: SelectOptions[] = [
	{ value: LOCALE_ALLOWED.ES, label: localeTypeMap.get(LOCALE_ALLOWED.ES) as string },
	{ value: LOCALE_ALLOWED.EN, label: localeTypeMap.get(LOCALE_ALLOWED.EN) as string },
	{ value: LOCALE_ALLOWED.PT, label: localeTypeMap.get(LOCALE_ALLOWED.PT) as string },
];

export const getCategoryNameById = (id: string, categories?: CategoryInfo[]) => {
	return categories?.find((c) => c.id === id);
};

export const getContentGroupNameById = (id: string, contentGroups?: ContentGroupInfo[]) => {
	return contentGroups?.find((contentGroup) => contentGroup.id === id);
};

export const formatBytes = (bytes: number, decimals = 2) => {
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
};

export const transformAssetToBase64 = async (file: File) => {
	return await new Promise<string>((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			resolve(reader.result as string);
		};
		reader.onerror = (error) => reject(error);
	});
};

export const toTop = () => {
	window.scrollTo({ top: 0, behavior: 'smooth' });
};
