import { AxiInfo } from './axi';
import { CategoryInfo } from './category';
import { CommonProperties, WithPaginationResponse } from './common';
import { ContentGroupInfo } from './content-group';
import { LevelInfo } from './level';
import { ObjectiveInfo } from './objectives';
import { ProfileInfo } from './profile';
import { SchoolInfo } from './school';
import { SkillInfo } from './skill';
import { StudentInfo } from './student';

export type RolesAllowed = 'super-admin' | 'admin' | 'school-manager' | 'teacher' | 'parent';

export const ROLES_ALLOWED = {
	SUPER_ADMIN: 'super-admin',
	ADMIN: 'admin',
	SCHOOL_MANAGER: 'school-manager',
	TEACHER: 'teacher',
	PARENT: 'parent',
};

export const rolesMap = new Map([
	[ROLES_ALLOWED.SUPER_ADMIN, 'Super Admin'],
	[ROLES_ALLOWED.ADMIN, 'Administrador'],
	[ROLES_ALLOWED.SCHOOL_MANAGER, 'Administrador de Colegio'],
	[ROLES_ALLOWED.TEACHER, 'Profesor'],
	[ROLES_ALLOWED.PARENT, 'Apoderado'],
]);

export interface UserInfo extends ProfileInfo, CommonProperties {
	identification?: string;
	isVerified: boolean;
	email: string;
	phone?: string;
	role: RolesAllowed;
	students?: StudentInfo[];
	shortcuts?: string[];
	courseName?: string;
	schoolName?: string;
}

export interface MeResponse {
	user: UserInfo;
	userList: UserInfo[];
	axis: AxiInfo[];
	categories: CategoryInfo[];
	contentGroups: ContentGroupInfo[];
	objectives: ObjectiveInfo[];
	skills: SkillInfo[];
	levels: LevelInfo[];
	schools: SchoolInfo[];
}

export interface UserState {
	info?: UserInfo;
	users?: UserInfo[];
}

export interface AllUSersResponse extends WithPaginationResponse {
	users: UserInfo[];
}
