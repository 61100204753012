import { createContext, Dispatch, PropsWithChildren, SetStateAction, useEffect, useState } from 'react';

export interface AppProviderProps {
	loading: boolean;
	showNav: boolean;
}

export interface AppProviderBase extends AppProviderProps {
	setLoading: Dispatch<SetStateAction<boolean>>;
	setShowNav: Dispatch<SetStateAction<boolean>>;
}

export const defaultAppValues: AppProviderBase = {
	loading: false,
	showNav: true,
	setLoading: () => {},
	setShowNav: () => {},
};

export const AppContext = createContext<AppProviderBase>(defaultAppValues);

export const AppProvider = ({ children }: PropsWithChildren<AppProviderBase>) => {
	const [loading, setLoading] = useState(false);
	const [showNav, setShowNav] = useState(true);

	useEffect(() => {
		window.addEventListener('resize', () => {
			if (window.innerWidth >= 1200) setShowNav(true);
			else setShowNav(false);
		});
	}, [window.innerWidth]);

	useEffect(() => {
		if (loading) document.body.style.overflow = 'hidden';
		else document.body.style.overflow = 'auto';

		setLoading(loading);
	}, [loading]);

	return <AppContext.Provider value={{ loading, setLoading, showNav, setShowNav }}>{children}</AppContext.Provider>;
};
