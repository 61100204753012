import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CategoryState } from '../../interfaces/category';

const initialState: CategoryState = {
	info: undefined,
};

export const categorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {
		setCategoryInfo: (state, action: PayloadAction<CategoryState>) => {
			state.info = action.payload.info;
		},
		clearCategoryInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setCategoryInfo, clearCategoryInfo } = categorySlice.actions;
export default categorySlice.reducer;
