import { ClientResponse, Pagination } from '../interfaces/common';
import { AllUSersResponse, MeResponse, UserInfo } from '../interfaces/user';
import { get, post, put } from '../utils/api';

const me = async () => {
	const { data } = await get<ClientResponse<MeResponse>>('/user/me');
	return data;
};

const resetPassword = async (uid: string) => {
	const { data } = await post<ClientResponse<UserInfo>>(`/user/reset-password/${uid}`);
	return data;
};

const getList = async ({ limit, current }: Pagination) => {
	const { data } = await get<ClientResponse<AllUSersResponse>>('/user/all', {
		params: {
			limit,
			current,
		},
	});
	return data;
};

const create = async (data: Omit<UserInfo, 'isActive'>) => {
	const { data: res } = await post<ClientResponse<UserInfo>>('/user/create', data);
	return res;
};

const createFromCSV = async (file: FileList | File) => {
	const { data: res } = await post<ClientResponse<UserInfo>>('/user/create-from-csv', file, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
	return res;
};

const update = async (uid: string, data: Omit<UserInfo, 'isActive'>) => {
	const { data: res } = await put<ClientResponse<UserInfo>>(`/user/update/${uid}`, data);
	return res;
};

const enable = async (uid: string) => {
	const { data } = await put<ClientResponse<UserInfo>>(`/user/enable/${uid}`);
	return data;
};

const disable = async (uid: string) => {
	const { data } = await put<ClientResponse<UserInfo>>(`/user/disable/${uid}`);
	return data;
};

export const UserServices = {
	me,
	getList,
	create,
	createFromCSV,
	update,
	enable,
	disable,
	resetPassword,
};
