import PersonIcon from '@mui/icons-material/Person';
import { Avatar, Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../hooks/useAuth';
import { useUser } from '../../../../hooks/useUser';

const UserMenu = () => {
	const { user } = useUser();
	const { handleLogout } = useAuth();
	const [userMenu, setUserMenu] = useState<HTMLElement | null>(null);

	const userMenuClick = (event: MouseEvent<HTMLElement>) => {
		setUserMenu(event.currentTarget);
	};

	const userMenuClose = () => {
		setUserMenu(null);
	};

	return (
		<>
			<Button className="min-h-40 min-w-40 p-0 md:px-16 md:py-6" onClick={userMenuClick} color="inherit">
				<Box className="mx-4 hidden flex-col items-end md:flex">
					<Typography component="span" className="flex font-semibold">
						{user?.name} {user?.firstSurname}
					</Typography>
					<Typography className="text-11 font-medium lowercase" color="text.secondary">
						{user?.email}
					</Typography>
				</Box>

				<Avatar className="md:mx-4">{user?.name[0]}</Avatar>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				classes={{
					paper: 'py-8',
				}}
			>
				<MenuItem component={Link} to="/profile" onClick={userMenuClose} role="button">
					<ListItemIcon>
						<PersonIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Mi perfil" />
				</MenuItem>

				{/* <MenuItem component={Link} to="/#" onClick={userMenuClose} role="button">
					<ListItemIcon>
						<SettingsIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Configuración" />
				</MenuItem> */}
				<Divider />
				<MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
			</Popover>
		</>
	);
};

export { UserMenu };
