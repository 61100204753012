import { StyledEngineProvider } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import es from 'date-fns/esm/locale/es';
import setDefaultOptions from 'date-fns/setDefaultOptions';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { theme } from './config/theme';
import { globalTheme } from './config/theme/global';
import { AppProvider, defaultAppValues } from './context/app';
import { AlertProvider, defaultAlertValues } from './context/custom-alert';
import { routesConfig } from './routes/routes';

const router = createBrowserRouter(routesConfig);
setDefaultOptions({ locale: es });

const App = () => {
	return (
		<LocalizationProvider dateAdapter={AdapterDateFns}>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<GlobalStyles styles={globalTheme} />
					<AppProvider {...defaultAppValues}>
						<AlertProvider {...defaultAlertValues}>
							<RouterProvider router={router} />
						</AlertProvider>
					</AppProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</LocalizationProvider>
	);
};

export { App };
