import { CommonProperties } from './common';

export type SexAllowed = 'male' | 'female' | 'other';

export const SEX_ALLOWED = {
	MALE: 'male',
	FEMALE: 'female',
	OTHER: 'other',
};

export const sexMap = new Map([
	[SEX_ALLOWED.MALE, 'Masculino'],
	[SEX_ALLOWED.FEMALE, 'Femenino'],
	[SEX_ALLOWED.OTHER, 'Otro'],
]);

export interface StudentInfo extends CommonProperties {
	identification?: string;
	name: string;
	firstSurname: string;
	secondSurname?: string;
	email?: string;
	sex: SexAllowed;
	avatar: string;
	courseName?: string;
	schoolName?: string;
}
