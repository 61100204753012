import { yupResolver } from '@hookform/resolvers/yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Alert, Button, InputAdornment, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { InferType } from 'yup';

import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../hooks/useUser';
import { AuthLayout } from '../../layouts/auth';
import { errorManager } from '../../utils/errors';
import { singInSchema } from '../../utils/validations';

const defaultValues = {
	email: '',
	password: '',
};

const SignInView = memo(() => {
	const { handleLogin } = useAuth();
	const { setUserInfo } = useUser();
	const [state, setState] = useState({
		loading: false,
		showPassword: false,
		errorCode: '',
	});
	const { control, formState, handleSubmit } = useForm({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(singInSchema),
	});

	const { isValid, dirtyFields, errors } = formState;

	const onSubmit = async ({ email, password }: InferType<typeof singInSchema>) => {
		setState((prev) => ({ ...prev, loading: true }));
		try {
			await handleLogin({ email, password });
			await setUserInfo();
		} catch (error: any) {
			setState((prev) => ({ ...prev, errorCode: errorManager(error) as string }));
			setTimeout(() => {
				setState((prev) => ({ ...prev, errorCode: '' }));
			}, 5000);
		} finally {
			setState((prev) => ({ ...prev, loading: false }));
		}
	};

	return (
		<AuthLayout
			tabTitle="Iniciar sesión"
			title="Te presentamos"
			description="Un mundo de historias para que tus hijos e hijas aprendan mientras se divierten."
			{...(state.errorCode && { errorMessage: state.errorCode })}
		>
			<>
				<Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">Iniciar sesión</Typography>

				<Alert icon={false} severity="info" className="mt-20">
					Estas a punto de entrar a tu portal en <b>KIDINT</b>. Utilice sus credenciales y luego haz click el botón{' '}
					<b>Entrar</b>.
				</Alert>

				<form
					name="sign-in-form"
					noValidate
					className="mt-32 flex w-full flex-col justify-center"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-16"
								label="Correo electrónico"
								type="email"
								error={!!errors.email}
								helperText={errors?.email?.message}
								variant="outlined"
								required
								fullWidth
							/>
						)}
					/>

					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-8"
								label="Contraseña"
								type={state.showPassword ? 'text' : 'password'}
								error={!!errors.password}
								helperText={errors?.password?.message}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment
											className="cursor-pointer"
											position="end"
											onClick={() => setState({ ...state, showPassword: !state.showPassword })}
										>
											{state.showPassword ? <VisibilityOff /> : <Visibility />}
										</InputAdornment>
									),
								}}
								required
								fullWidth
							/>
						)}
					/>

					<div className="flex flex-col items-end">
						<Link className="text-md font-medium" to="/recovery-password">
							¿Olvidó su contraseña?
						</Link>
					</div>

					<Button
						variant="contained"
						color="primary"
						className=" mt-16 w-full"
						aria-label="Entrar"
						disabled={_.isEmpty(dirtyFields) || !isValid || state.loading}
						type="submit"
						size="large"
					>
						{state.loading ? 'Cargando...' : 'Entrar'}
					</Button>
				</form>
			</>
		</AuthLayout>
	);
});

SignInView.displayName = 'SignInView';
export { SignInView };
