import { AxiInfo } from '../interfaces/axi';
import { setAxiInfo } from '../store/axi';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const useAxi = () => {
	const dispatch = useAppDispatch();
	const axiInfo = useAppSelector((state) => state.axi.info);

	const setAxiInfoAction = async (info: AxiInfo[]) => {
		try {
			dispatch(setAxiInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const axis = axiInfo?.filter((axi) => axi.isActive);

	return { axis, setAxis: setAxiInfoAction };
};

export { useAxi };
