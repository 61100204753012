import { ClientResponse } from '../interfaces/common';
import { post } from '../utils/api';

export interface LoginParams {
	email: string;
	password: string;
}

const login = async ({ email, password }: LoginParams) => {
	const { data } = await post<ClientResponse<{ token: string }>>('/auth/sign-in', { email, password });
	return data.data;
};

const recoveryPassword = async (email: string) => {
	await post('/auth/recovery-password', { email });
};

export const AuthServices = {
	login,
	recoveryPassword,
};
