import { ContentGroupInfo } from '../interfaces/content-group';
import { setContentGroupInfo } from '../store/content-groups';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const useContentGroups = () => {
	const dispatch = useAppDispatch();
	const contentGroupInfo = useAppSelector((state) => state.contentGroup.info);

	const setContentGroupInfoAction = async (info: ContentGroupInfo[]) => {
		try {
			dispatch(setContentGroupInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const contentGroups = contentGroupInfo?.filter((contentGroup) => contentGroup.isActive);

	return { contentGroups, setContentGroups: setContentGroupInfoAction };
};

export { useContentGroups };
