import { LevelInfo } from '../interfaces/level';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setLevelInfo } from '../store/level';

const useLevels = () => {
	const dispatch = useAppDispatch();
	const levelsInfo = useAppSelector((state) => state.levels.info);

	const setLevelsInfoAction = async (info: LevelInfo[]) => {
		try {
			dispatch(setLevelInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const levels = levelsInfo?.filter((level) => level.isActive).sort((a, b) => a.name.localeCompare(b.name));

	return { levels, setLevels: setLevelsInfoAction };
};

export { useLevels };
