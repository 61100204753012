import { SkillInfo } from '../interfaces/skill';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setSkillInfo } from '../store/skill';

const useSkills = () => {
	const dispatch = useAppDispatch();
	const skillsInfo = useAppSelector((state) => state.skill.info);

	const setSkillsInfoAction = async (info: SkillInfo[]) => {
		try {
			dispatch(setSkillInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const skills = skillsInfo?.filter((skill) => skill.isActive);

	return { skills, setSkills: setSkillsInfoAction };
};

export { useSkills };
