import { createTheme } from '@mui/material/styles';
import { ThemeOptions } from '@mui/material/styles/createTheme';
import _ from 'lodash';

import { defaultTheme, defaultThemeOptions, mustHaveThemeOptions } from './overrides';

const data = _.merge({}, defaultThemeOptions, defaultTheme.default, mustHaveThemeOptions) as ThemeOptions;
const darkData = _.merge({}, defaultThemeOptions, defaultTheme.defaultDark, mustHaveThemeOptions) as ThemeOptions;

const extendThemeWithMixins = (obj: ThemeOptions) => {
	const theme = createTheme(obj);
	return {
		border: (width = 1) => ({
			borderWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
		}),
		borderLeft: (width = 1) => ({
			borderLeftWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
		}),
		borderRight: (width = 1) => ({
			borderRightWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
		}),
		borderTop: (width = 1) => ({
			borderTopWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
		}),
		borderBottom: (width = 1) => ({
			borderBottomWidth: width,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
		}),
	};
};

export const theme = createTheme(
	_.merge({}, data, {
		mixins: extendThemeWithMixins(data),
		direction: 'ltr',
	} as ThemeOptions),
);
export const darkTheme = createTheme(
	_.merge({}, darkData, {
		mixins: extendThemeWithMixins(data),
		direction: 'ltr',
	} as ThemeOptions),
);
