import { ObjectiveInfo } from '../interfaces/objectives';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setObjectiveInfo } from '../store/objective';

const useObjectives = () => {
	const dispatch = useAppDispatch();
	const objectivesInfo = useAppSelector((state) => state.objective.info);

	const setObjectivesInfoAction = (objectives: ObjectiveInfo[]) => {
		try {
			dispatch(setObjectiveInfo({ info: objectives }));
		} catch (error) {
			return error;
		}
	};

	const objectives = objectivesInfo?.filter((objective) => objective.isActive);

	return { objectives, setObjectives: setObjectivesInfoAction };
};

export { useObjectives };
