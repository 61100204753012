import { Alert, Box, Paper } from '@mui/material';
import _ from 'lodash';
import { PropsWithChildren, useEffect } from 'react';
import { useNavigate } from 'react-router';

import { WrapperPage } from '../../components/wrapper-page';
import { useUser } from '../../hooks/useUser';

interface AuthLayoutProps {
	tabTitle: string;
	title?: string;
	description?: string;
	errorMessage?: string;
}

const AuthLayout = ({ children, description, title, tabTitle, errorMessage }: PropsWithChildren<AuthLayoutProps>) => {
	const { user } = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (!_.isEmpty(user)) {
			if (!user.isVerified) navigate('/profile');
			else navigate('/');
		}
	}, [user]);

	return (
		<WrapperPage title={tabTitle}>
			<div className="flex w-full">
				<div className="flex min-w-0 flex-auto">
					<main className="relative z-10 flex min-h-full min-w-0 flex-auto flex-col">
						<div className="relative z-10 flex min-h-0 flex-auto flex-col">
							<div className="flex min-w-0 flex-1 flex-col items-center sm:flex-row sm:justify-center md:items-start md:justify-start">
								<Paper className="relative h-full w-full px-16 py-8 ltr:border-r-1 rtl:border-l-1 sm:h-auto sm:w-auto sm:rounded-2xl sm:p-48 sm:shadow md:flex md:h-full md:w-1/2 md:items-center md:justify-end md:rounded-none md:p-64 md:shadow-none">
									{!!errorMessage && (
										<Box className="absolute min-w-xs w-fit top-64 left-0 right-0 mx-auto">
											<Alert severity="error" icon={false}>
												{errorMessage}
											</Alert>
										</Box>
									)}

									<div className="mx-auto w-full max-w-360 sm:mx-0 sm:w-360">
										<div className="w-1/2">
											<img src="/images/logo.svg" alt="logo" />
										</div>
										{children}
									</div>
								</Paper>

								<Box
									className="relative hidden h-full flex-auto items-center justify-center overflow-hidden p-64 md:flex lg:px-112"
									sx={{ backgroundColor: 'primary.main' }}
								>
									<svg
										className="pointer-events-none absolute inset-0"
										viewBox="0 0 960 540"
										width="100%"
										height="100%"
										preserveAspectRatio="xMidYMax slice"
										xmlns="http://www.w3.org/2000/svg"
									>
										<Box
											component="g"
											sx={{ color: 'primary.light' }}
											className="opacity-20"
											fill="none"
											stroke="currentColor"
											strokeWidth="100"
										>
											<circle r="234" cx="196" cy="23" />
											<circle r="234" cx="790" cy="491" />
										</Box>
									</svg>
									<Box
										component="svg"
										className="absolute -right-64 -top-64 opacity-20"
										sx={{ color: 'primary.light' }}
										viewBox="0 0 220 192"
										width="220px"
										height="192px"
										fill="none"
									>
										<defs>
											<pattern
												id="837c3e70-6c3a-44e6-8854-cc48c737b659"
												x="0"
												y="0"
												width="20"
												height="20"
												patternUnits="userSpaceOnUse"
											>
												<rect x="0" y="0" width="4" height="4" fill="currentColor" />
											</pattern>
										</defs>
										<rect width="220" height="192" fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)" />
									</Box>

									<div className="relative z-10 w-full max-w-2xl">
										<div className="text-7xl font-bold leading-none text-gray-100">
											<div>{title}</div>
											<div className="w-1/3 mt-20 mb-8">
												<img src="/images/logo.svg" alt="logo" />
											</div>
										</div>
										<div className="mt-24 text-lg leading-6 tracking-tight text-gray-400">{description}</div>
									</div>
								</Box>
							</div>
						</div>
					</main>
				</div>
			</div>
		</WrapperPage>
	);
};

export { AuthLayout };
