import { Star, StarOutline } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import { MouseEvent } from 'react';
import { Link } from 'react-router-dom';

import { NavigationItem } from '../../../../../../routes/navigation';

interface ShortcutItemProps {
	nav: NavigationItem;
	isActive?: boolean;
	onClick: (nav: NavigationItem) => void;
}

const ShortcutItem = ({ nav, isActive = false, onClick }: ShortcutItemProps) => {
	const handleClick = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
		e.stopPropagation();
		onClick(nav);
	};

	return (
		<Box className="flex items-center justify-between py-8 px-16 text-gray-600">
			<Link to={nav.to} role="button">
				<Box className="flex items-center">
					<Box className="flex items-center">
						<nav.icon />
						<Box className="ml-16">{nav.title}</Box>
					</Box>
				</Box>
			</Link>

			<IconButton aria-label="Agregar atajo" onClick={handleClick} className="p-4">
				{isActive ? <Star /> : <StarOutline />}
			</IconButton>
		</Box>
	);
};

export { ShortcutItem };
