import { CategoryInfo } from '../interfaces/category';
import { setCategoryInfo } from '../store/category';
import { useAppDispatch, useAppSelector } from '../store/hooks';

const useCategory = () => {
	const dispatch = useAppDispatch();
	const categoriesInfo = useAppSelector((state) => state.category.info);

	const setCategoriesInfoAction = async (info: CategoryInfo[]) => {
		try {
			dispatch(setCategoryInfo({ info }));
		} catch (error) {
			return error;
		}
	};

	const categories = categoriesInfo?.filter((category) => category.isActive);

	return { categories, setCategories: setCategoriesInfoAction };
};

export { useCategory };
