import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { IconButton, Tooltip } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';

type FullScreenDocumentType = Document & {
	mozCancelFullScreen?: () => void;
	msExitFullscreen?: () => void;
	webkitExitFullscreen?: () => void;
	mozFullScreenElement?: Element | null;
	msFullscreenElement?: Element | null;
	webkitFullscreenElement?: Element | null;
};

type FullScreenHTMLElementType = HTMLElement & {
	mozRequestFullScreen?: () => void;
	webkitRequestFullscreen?: () => void;
	msRequestFullscreen?: () => void;
};

const useEnhancedEffect = typeof window !== 'undefined' ? useLayoutEffect : useEffect;

const Fullscreen = () => {
	const [isFullScreen, setIsFullScreen] = useState(false);

	useEnhancedEffect(() => {
		document.onfullscreenchange = () =>
			setIsFullScreen((document as FullScreenDocumentType)[getBrowserFullscreenElementProp()] != null);

		return () => {
			document.onfullscreenchange = null;
		};
	});

	const getBrowserFullscreenElementProp = (): keyof FullScreenDocumentType => {
		const doc: FullScreenDocumentType = document as FullScreenDocumentType;

		if (typeof doc.fullscreenElement !== 'undefined') return 'fullscreenElement';
		if (typeof doc.mozFullScreenElement !== 'undefined') return 'mozFullScreenElement';
		if (typeof doc.msFullscreenElement !== 'undefined') return 'msFullscreenElement';
		if (typeof doc.webkitFullscreenElement !== 'undefined') return 'webkitFullscreenElement';
		throw new Error('La pantalla completa no es compatible con este navegador.');
	};

	const openFullscreen = () => {
		const elem: FullScreenHTMLElementType = document.documentElement;

		if (elem.requestFullscreen) elem.requestFullscreen();
		else if (elem.mozRequestFullScreen) elem.mozRequestFullScreen();
		else if (elem.webkitRequestFullscreen) elem.webkitRequestFullscreen();
		else if (elem.msRequestFullscreen) elem.msRequestFullscreen();
	};

	const closeFullscreen = () => {
		const doc: FullScreenDocumentType = document;
		if (doc.exitFullscreen) doc.exitFullscreen();
		else if (doc.mozCancelFullScreen) doc.mozCancelFullScreen();
		else if (doc.webkitExitFullscreen) doc.webkitExitFullscreen();
		else if (doc.msExitFullscreen) doc.msExitFullscreen();
	};

	const toggleFullScreen = () => {
		const doc: FullScreenDocumentType = document;

		if (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement) {
			closeFullscreen();
		} else {
			openFullscreen();
		}
	};

	return (
		<Tooltip title="Pantalla completa" placement="bottom">
			<IconButton
				onClick={toggleFullScreen}
				className={`h-40 w-40 ${isFullScreen ? ' text-red-500' : ''}`}
				size="large"
			>
				<FullscreenIcon />
			</IconButton>
		</Tooltip>
	);
};

export { Fullscreen };
