import { ProfileInfo } from '../interfaces/profile';
import { UserInfo } from '../interfaces/user';
import { ShortcutsServices } from '../services/shortcuts';
import { UserServices } from '../services/user';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setUserInfo as setUserInfoSlice, setUserList } from '../store/user';
import { useAxi } from './useAxi';
import { useCategory } from './useCategories';
import { useContentGroups } from './useContentGroups';
import { useLevels } from './useLevels';
import { useObjectives } from './useObjectives';
import { useSchools } from './useSchool';
import { useSkills } from './useSkills';

const useUser = () => {
	const dispatch = useAppDispatch();
	const { info, users } = useAppSelector((state) => state.user);

	const { setCategories } = useCategory();
	const { setAxis } = useAxi();
	const { setContentGroups } = useContentGroups();
	const { setSkills } = useSkills();
	const { setObjectives } = useObjectives();
	const { setLevels } = useLevels();
	const { setSchools } = useSchools();

	const setUserInfo = async () => {
		try {
			const response = await UserServices.me();
			setCategories(response.data.categories);
			setContentGroups(response.data.contentGroups);
			setSkills(response.data.skills);
			setObjectives(response.data.objectives);
			setAxis(response.data.axis);
			setLevels(response.data.levels);
			setSchools(response.data.schools);
			dispatch(setUserInfoSlice(response.data.user));
			dispatch(setUserList(response.data.userList));
		} catch (error) {
			return error;
		}
	};

	const setProfileInfo = async (profile: ProfileInfo) => {
		const _info = { ...info, ...profile } as UserInfo;
		dispatch(setUserInfoSlice(_info));
	};

	const setShortcuts = async (shortcuts: string[]) => {
		const _info = { ...info, shortcuts } as UserInfo;
		dispatch(setUserInfoSlice(_info));
		await ShortcutsServices.modifyShortcuts(shortcuts);
	};

	return {
		user: info,
		setUserInfo,
		setProfileInfo,
		setShortcuts,
		users,
	};
};

export { useUser };
