import {
	AccountBox,
	AccountTree,
	AddModerator,
	AddTask,
	AutoStories,
	Category,
	Class,
	Group,
	Home,
	Hub,
	Moving,
	School,
	Subject,
	Workspaces,
} from '@mui/icons-material';
import { ElementType } from 'react';

import { RolesAllowed, UserInfo } from '../interfaces/user';

export interface NavigationItem {
	id: string;
	title: string;
	icon: ElementType;
	show: boolean | ((user: UserInfo) => boolean);
	to: string;
	rolesAllowed: RolesAllowed[];
}

interface NavigationSection {
	id: string;
	title: string;
	show: boolean | ((arg: UserInfo) => boolean);
	subtitle?: string;
	rolesAllowed: RolesAllowed[];
	children: NavigationItem[];
}

export const ROUTES = {
	HOME: '/',
	USERS: '/users',
	STUDENTS: '/students',
	SCHOOLS: '/schools',
	COURSES: '/courses',
	SUBJECT: '/subjects',
	SKILLS: '/skills',
	AXIS: '/axis',
	LEARNING_OBJECTIVES: '/objectives',
	LEVELS: '/levels',
	CONTENT_GROUPS: '/content-groups',
	RELATION_MAKER: '/relation-maker',
	CATEGORIES: '/categories',
	BOOKS: '/books',
};

export const navigation: NavigationSection[] = [
	{
		id: 'dashboard',
		title: 'Resumen',
		show: true,
		subtitle: 'Resumen de la plataforma',
		rolesAllowed: ['super-admin', 'admin', 'school-manager'],
		children: [
			{
				id: 'home',
				title: 'Inicio',
				show: true,
				to: ROUTES.HOME,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Home,
			},
		],
	},
	{
		id: 'users',
		title: 'Usuarios',
		show: true,
		subtitle: 'Gestión de usuarios',
		rolesAllowed: ['super-admin', 'admin'],
		children: [
			{
				id: 'users',
				title: 'Usuarios',
				show: true,
				to: ROUTES.USERS,
				rolesAllowed: ['super-admin', 'admin'],
				icon: Group,
			},
			{
				id: 'students',
				title: 'Estudiantes',
				show: true,
				to: ROUTES.STUDENTS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: AccountBox,
			},
		],
	},
	{
		id: 'modules',
		title: 'Módulos',
		show: true,
		subtitle: 'Gestión de módulos',
		rolesAllowed: ['super-admin', 'admin', 'school-manager'],
		children: [
			{
				id: 'levels',
				title: 'Niveles',
				show: true,
				to: ROUTES.LEVELS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Moving,
			},
			{
				id: 'content-groups',
				title: 'Grupo de contenidos',
				show: true,
				to: ROUTES.CONTENT_GROUPS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Workspaces,
			},
			{
				id: 'learning-objectives',
				title: 'Objetivos de aprendizaje',
				show: true,
				to: ROUTES.LEARNING_OBJECTIVES,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: AddTask,
			},
			{
				id: 'axis',
				title: 'Ejes',
				show: true,
				to: ROUTES.AXIS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: AccountTree,
			},
			{
				id: 'skills',
				title: 'Habilidades',
				show: true,
				to: ROUTES.SKILLS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: AddModerator,
			},
			{
				id: 'subject',
				title: 'Asignaturas',
				show: true,
				to: ROUTES.SUBJECT,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Subject,
			},
			{
				id: 'category',
				title: 'Categorías',
				show: true,
				to: ROUTES.CATEGORIES,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Category,
			},
			{
				id: 'books',
				title: 'Libros',
				show: true,
				to: ROUTES.BOOKS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: AutoStories,
			},
		],
	},
	{
		id: 'schools',
		title: 'Colegios',
		show: true,
		subtitle: 'Gestión de colegios y su contenido',
		rolesAllowed: ['super-admin', 'admin', 'school-manager'],
		children: [
			{
				id: 'courses',
				title: 'Cursos',
				show: true,
				to: ROUTES.COURSES,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Class,
			},
			{
				id: 'schools',
				title: 'Colegios',
				show: true,
				to: ROUTES.SCHOOLS,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: School,
			},
			{
				id: 'relation-maker',
				title: 'Arma el contenido',
				show: true,
				to: ROUTES.RELATION_MAKER,
				rolesAllowed: ['super-admin', 'admin', 'school-manager'],
				icon: Hub,
			},
		],
	},
];
