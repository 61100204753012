import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Button, TextField, Typography } from '@mui/material';
import _ from 'lodash';
import { memo, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { InferType } from 'yup';

import { useAuth } from '../../hooks/useAuth';
import { AuthLayout } from '../../layouts/auth';
import { errorManager } from '../../utils/errors';
import { recoveryPasswordSchema } from '../../utils/validations';

const defaultValues = {
	email: '',
};

const RecoveryPasswordView = memo(() => {
	const { handleRecoveryPassword } = useAuth();
	const navigate = useNavigate();
	const [state, setState] = useState({
		loading: false,
		showPassword: false,
		success: false,
		errorCode: '',
	});
	const { control, formState, handleSubmit } = useForm({
		mode: 'all',
		defaultValues,
		resolver: yupResolver(recoveryPasswordSchema),
	});

	const { isValid, dirtyFields, errors } = formState;
	const emailValue = useWatch({ control, name: 'email' });

	const onSubmit = async ({ email }: InferType<typeof recoveryPasswordSchema>) => {
		setState((prev) => ({ ...prev, loading: true }));
		try {
			await handleRecoveryPassword(email);
			setState((prev) => ({ ...prev, success: true }));
			setTimeout(() => {
				navigate('/sign-in');
			}, 5000);
		} catch (error: any) {
			setState((prev) => ({ ...prev, errorCode: errorManager(error) as string }));
		} finally {
			setState((prev) => ({ ...prev, loading: false }));
		}
	};

	return (
		<AuthLayout
			tabTitle="Recuperar contraseña"
			title="Te presentamos"
			description="Un mundo de historias para que tus hijos e hijas aprendan mientras se divierten."
			{...(state.errorCode && { errorMessage: state.errorCode })}
		>
			<>
				<Typography className="mt-32 text-4xl font-extrabold leading-tight tracking-tight">
					Recuperar contraseña
				</Typography>

				<Alert icon={false} severity="info" className="mt-20">
					A continuación, ingrese su correo electrónico y le enviaremos una contraseña temporal para que pueda{' '}
					<b>iniciar sesión.</b>
				</Alert>

				<form
					name="recover-password-form"
					noValidate
					className="mt-32 flex w-full flex-col justify-center"
					onSubmit={handleSubmit(onSubmit)}
				>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<TextField
								{...field}
								className="mb-16"
								label="Correo electrónico"
								type="email"
								error={!!errors.email}
								helperText={errors?.email?.message}
								variant="outlined"
								required
								fullWidth
							/>
						)}
					/>

					<div className="flex flex-col items-end">
						<Link className="text-md font-medium" to="/sign-in">
							Volver a inicio de sesión
						</Link>
					</div>

					{state.success && (
						<Alert icon={false} severity="success" className="mt-10">
							Se ha enviado un correo a <b>{emailValue}</b> con una contraseña temporal para que pueda iniciar sesión.
							En breve, será redirigido a la página de inicio de sesión.
						</Alert>
					)}

					<Button
						variant="contained"
						color="secondary"
						className=" mt-16 w-full"
						aria-label="Entrar"
						disabled={_.isEmpty(dirtyFields) || !isValid || state.loading}
						type="submit"
						size="large"
					>
						{state.loading ? 'Cargando...' : 'Recuperar contraseña'}
					</Button>
				</form>
			</>
		</AuthLayout>
	);
});

RecoveryPasswordView.displayName = 'RecoveryPasswordView';
export { RecoveryPasswordView };
