import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SkillState } from '../../interfaces/skill';

const initialState: SkillState = {
	info: undefined,
};

export const skillSlice = createSlice({
	name: 'skill',
	initialState,
	reducers: {
		setSkillInfo: (state, action: PayloadAction<SkillState>) => {
			state.info = action.payload.info;
		},
		clearSkillInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setSkillInfo, clearSkillInfo } = skillSlice.actions;
export default skillSlice.reducer;
