import { Grade } from '@mui/icons-material';
import { Box, Divider, IconButton, Input, Popover, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, Fragment, MouseEvent, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { useUser } from '../../../../hooks/useUser';
import { navigation, NavigationItem } from '../../../../routes/navigation';
import { ShortcutItem } from './components/item';

const Shortcut = () => {
	const { user, setShortcuts } = useUser();
	const searchInputRef = useRef<HTMLInputElement>(null);
	const [searchText, setSearchText] = useState('');
	const [showMenuElement, setShowMenuElement] = useState<null | HTMLElement>(null);
	const [elementsFound, setElementsFound] = useState<NavigationItem[]>([]);

	const handleSearchText = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setSearchText(value);

		if (value !== '') {
			const onlyChildrenFiltered = navigation.reduce(
				(acc: NavigationItem[], nav) =>
					nav.children
						? [
								...acc,
								...nav.children.filter(
									(child) =>
										child.title.toLowerCase().includes(searchText.toLowerCase()) &&
										!user?.shortcuts?.includes(`${child.id}.${child.to.split('/')[1]}`) &&
										child.rolesAllowed.includes(user?.role || 'admin'),
								),
						  ]
						: acc,
				[],
			);
			setElementsFound(onlyChildrenFiltered);
		} else {
			setElementsFound([]);
		}
	};

	const verifyShortcut = (shortcut: string) => {
		const [_id, _to] = shortcut.split('.');
		const onlyChildrenFiltered = navigation.reduce(
			(acc: NavigationItem[], nav) =>
				nav.children
					? [...acc, ...nav.children.filter((child) => child.title.toLowerCase().includes(searchText.toLowerCase()))]
					: acc,
			[],
		);
		return onlyChildrenFiltered.find((nav) => nav.id === _id && nav.to.split('/')[1] === _to);
	};

	const createShortcut = (nav: NavigationItem) => {
		const shortcut = `${nav.id}.${nav.to.split('/')[1]}`;
		const oldShortcuts = user?.shortcuts || [];
		const newShortcuts = oldShortcuts.includes(shortcut)
			? oldShortcuts.filter((oldShortcut) => oldShortcut !== shortcut)
			: [...oldShortcuts, shortcut];
		setShortcuts(newShortcuts);
		setSearchText('');
	};

	const addMenuClick = (event: MouseEvent<HTMLElement>) => {
		setShowMenuElement(event.currentTarget);
	};

	const addMenuClose = () => {
		setShowMenuElement(null);
	};

	return (
		<Box>
			{useMemo(() => {
				return (
					<Box className="flex flex-wrap w-full justify-center">
						{user?.shortcuts?.map((shortcut) => {
							const shortcutFound = verifyShortcut(shortcut);
							const Icon = shortcutFound?.icon || Fragment;

							return (
								<Tooltip key={shortcutFound?.id} title={shortcutFound?.title} placement="bottom">
									<Link to={shortcutFound?.to || ''}>
										<IconButton className="h-40 w-40 p-0" aria-haspopup="true" size="large">
											<Box className="flex items-center justify-center w-40 h-40">
												<Icon />
											</Box>
										</IconButton>
									</Link>
								</Tooltip>
							);
						})}

						<Tooltip title="Agregar o quitar atajos" placement="bottom">
							<IconButton className="h-40 w-40 p-0" aria-haspopup="true" size="large" onClick={addMenuClick}>
								<Grade className="text-amber-500" />
							</IconButton>
						</Tooltip>
					</Box>
				);
			}, [showMenuElement, user?.shortcuts])}

			<Popover
				id="navigation-items-menu"
				anchorEl={showMenuElement}
				open={!!showMenuElement}
				onClose={addMenuClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				classes={{
					paper: 'py-8 w-320',
				}}
			>
				<Box className="px-16">
					<Input
						inputRef={searchInputRef}
						value={searchText}
						onChange={handleSearchText}
						placeholder="Buscar atajos"
						fullWidth
						inputProps={{
							'aria-label': 'Buscar atajos',
						}}
						disableUnderline
					/>
				</Box>

				{useMemo(() => {
					if (searchText === '' && user?.shortcuts?.length !== 0) {
						return (
							<>
								<Divider />

								{user?.shortcuts?.map((shortcut) => {
									const shortcutFound = verifyShortcut(shortcut);

									return shortcutFound ? (
										<ShortcutItem key={shortcutFound?.id} nav={shortcutFound} onClick={createShortcut} isActive />
									) : null;
								})}
							</>
						);
					}
				}, [searchText, user?.shortcuts])}

				{useMemo(() => {
					if (searchText === '') return null;

					if (searchText.length !== 0 && elementsFound.length === 0)
						return <Typography className="p-16 pb-8">Sin resultados</Typography>;

					return (
						<>
							<Divider />

							{elementsFound.map((nav) => (
								<ShortcutItem key={nav.id} nav={nav} onClick={createShortcut} />
							))}
						</>
					);
				}, [searchText, user?.shortcuts])}
			</Popover>
		</Box>
	);
};

export { Shortcut };
