import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ContentGroupState } from '../../interfaces/content-group';

const initialState: ContentGroupState = {
	info: undefined,
};

export const contentGroupsSlice = createSlice({
	name: 'content-groups',
	initialState,
	reducers: {
		setContentGroupInfo: (state, action: PayloadAction<ContentGroupState>) => {
			state.info = action.payload.info;
		},
		clearContentGroupInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setContentGroupInfo, clearContentGroupInfo } = contentGroupsSlice.actions;
export default contentGroupsSlice.reducer;
