import { Box, Button, Typography } from '@mui/material';

import { BodyPage } from '../body-page';
import { WrapperPage } from '../wrapper-page';

const ErrorBoundary = () => {
	const handleReloadPage = () => {
		window.location.reload();
	};

	return (
		<WrapperPage title="Información no disponible">
			<BodyPage>
				<Box className="flex flex-col items-center justify-center w-full h-full">
					<Box className="mb-24 flex items-center justify-center pt-20">
						<Box className="w-2/5">
							<img src="/images/logo.svg" alt="logo" />
						</Box>
					</Box>

					<Typography variant="body1" component="h1" className="font-bold text-2xl">
						Información no disponible
					</Typography>

					<Typography variant="body2" component="h2">
						Si el error continúa, ponte en contacto con soporte.
					</Typography>

					<Box className="flex flex-row mt-20">
						<Button color="primary" variant="contained" onClick={handleReloadPage}>
							Recargar la página
						</Button>
					</Box>
				</Box>
			</BodyPage>
		</WrapperPage>
	);
};

export { ErrorBoundary };
