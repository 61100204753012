import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { LevelState } from '../../interfaces/level';

const initialState: LevelState = {
	info: undefined,
};

export const levelSlice = createSlice({
	name: 'level',
	initialState,
	reducers: {
		setLevelInfo: (state, action: PayloadAction<LevelState>) => {
			state.info = action.payload.info;
		},
		clearLevelInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setLevelInfo, clearLevelInfo } = levelSlice.actions;
export default levelSlice.reducer;
