import { CommonProperties, WithPaginationResponse } from './common';

export type LocaleType = 'es' | 'en' | 'pt';

export type AnswerType =
	| 'medium-question'
	| 'challenge'
	| 'activity'
	| 'email'
	| 'vocabulary'
	| 'tooltip'
	| 'cutout'
	| 'flashcard'
	| 'worksheet';

export const LOCALE_ALLOWED = {
	ES: 'es',
	EN: 'en',
	PT: 'pt',
};

export const ANSWER_ALLOWED = {
	MEDIUM_QUESTION: 'medium-question',
	CHALLENGE: 'challenge',
	ACTIVITY: 'activity',
	EMAIL: 'email',
	VOCABULARY: 'vocabulary',
	TOOLTIP: 'tooltip',
	CUTOUT: 'cutout',
	FLASHCARD: 'flashcard',
	WORKSHEET: 'worksheet',
};

export const localeTypeMap = new Map([
	[LOCALE_ALLOWED.EN, 'English'],
	[LOCALE_ALLOWED.ES, 'Español'],
	[LOCALE_ALLOWED.PT, 'Portugués'],
]);

export const answerTypeMap = new Map([
	[ANSWER_ALLOWED.MEDIUM_QUESTION, 'Pregunta media'],
	[ANSWER_ALLOWED.CHALLENGE, 'Desafío'],
	[ANSWER_ALLOWED.ACTIVITY, 'Actividad'],
	[ANSWER_ALLOWED.EMAIL, 'Correo'],
	[ANSWER_ALLOWED.VOCABULARY, 'Vocabulario'],
	[ANSWER_ALLOWED.TOOLTIP, 'Tooltip'],
	[ANSWER_ALLOWED.CUTOUT, 'Separar'],
	[ANSWER_ALLOWED.FLASHCARD, 'Tarjetas de memoria'],
	[ANSWER_ALLOWED.WORKSHEET, 'Hoja de trabajo'],
]);

export interface Meta {
	author: string;
	editor: string;
	composer: string;
	illustrator: string;
	storyteller: string;
	featured: boolean;
}

export interface FileInfo {
	url: string;
	contentType: string;
	fileSize: number;
}

export interface PageInfo {
	uid: string;
	image?: FileInfo;
	audio?: FileInfo;
	subtitle: string;
	srtFile?: FileInfo;
}

export interface Answer {
	uid: string;
	text: string;
	image?: FileInfo;
	audio: FileInfo;
	isCorrect: boolean;
}

export interface QuestionInfo {
	uid: string;
	title: string;
	audio: FileInfo;
	image?: FileInfo;
	skill: string;
	objective: string;
	level: string;
	schoolID: string;
	answers: Answer[];
}

export interface BookInfo extends CommonProperties {
	_uid: string;
	name: string;
	description: string;
	locale: LocaleType;
	contentGroupID: string[];
	categoryID: string[];
	levelID: string[];
	meta: Meta;
	coverImage: FileInfo;
	backgroundAudio: FileInfo;
	pages?: PageInfo[];
	questions?: QuestionInfo[];
}

export interface CreateBookResponse {
	bookId: string;
}

export interface AllBooksResponse extends WithPaginationResponse {
	books: BookInfo[];
}

export interface CreateBookProperties
	extends Omit<
		BookInfo,
		'isActive' | 'createdAt' | 'updatedAt' | 'deleteAt' | '_uid' | 'coverImage' | 'backgroundAudio'
	> {
	image: File;
	audio: File;
}

export interface PageContent {
	image?: File;
	audio?: File;
	srtFile?: File;
	subtitle?: string;
}

export interface AnswerQuestion {
	text?: string;
	image?: File;
	audio?: File;
	isCorrect?: boolean;
}

export interface QuestionContent {
	title?: string;
	audio?: File;
	image?: File;
	skill?: string;
	objective?: string;
	level?: string;
	schoolID?: string;
	answers?: AnswerQuestion[];
}

export interface AnswerQuestionPrueba {
	text?: string;
	image?: string;
	audio?: string;
	isCorrect?: boolean;
}

export interface QuestionContentPrueba {
	title?: string;
	audio?: string;
	image?: string;
	skills?: string;
	objectives?: string;
	groupContentID?: string;
	answers?: AnswerQuestionPrueba[];
}

export interface PageContentToEndpoint {
	image: string;
	audio: string;
	srtFile?: string;
	subtitle?: string;
}

export interface CreateBookPropertiesToEndpoint
	extends Omit<
		BookInfo,
		'isActive' | 'createdAt' | 'updatedAt' | 'deleteAt' | '_uid' | 'coverImage' | 'backgroundAudio'
	> {
	image: string;
	audio?: string;
}

export interface DeletePageParams {
	bookID: string;
	pageID: string;
}

export interface DeleteQuestionParams {
	bookID: string;
	questionID: string;
}

export interface UpdateQuestionParams {
	bookID: string;
	questionID: string;
	body: QuestionContentPrueba;
}
