import { lazy } from 'react';

import { ErrorBoundary } from '../components/error-boundary';
import { DashboardLayout } from '../layouts/dashboard';
import { RecoveryPasswordView } from '../views/auth/recovery-password';
import { SignInView } from '../views/auth/sign-in';

const AxisView = lazy(() => import('../views/axis'));
const BooksView = lazy(() => import('../views/books'));
const CategoryView = lazy(() => import('../views/category'));
const CoursesView = lazy(() => import('../views/courses'));
const CreateBooksView = lazy(() => import('../views/books/views/create'));
const EditBookView = lazy(() => import('../views/books/views/edit'));
const HomeView = lazy(() => import('../views/home'));
const InfoBookView = lazy(() => import('../views/books/views/info'));
const LevelsView = lazy(() => import('../views/levels'));
const ObjectivesView = lazy(() => import('../views/objectives'));
const ProfileView = lazy(() => import('../views/profile'));
const RelationMakerView = lazy(() => import('../views/relation-maker'));
const SchoolsView = lazy(() => import('../views/schools'));
const SkillsView = lazy(() => import('../views/skills'));
const StudentsView = lazy(() => import('../views/students'));
const SubjectsView = lazy(() => import('../views/subjects'));
const UsersView = lazy(() => import('../views/users'));
const ContentGroupsView = lazy(() => import('../views/content-groups'));

export const routesConfig = [
	{
		path: '/sign-in',
		element: <SignInView />,
	},
	{
		path: '/recovery-password',
		element: <RecoveryPasswordView />,
	},
	{
		element: <DashboardLayout />,
		path: '/',
		children: [
			{
				index: true,
				element: <HomeView />,
			},
			{
				path: 'users',
				element: <UsersView />,
			},
			{
				path: 'students',
				element: <StudentsView />,
			},
			{
				path: 'schools',
				element: <SchoolsView />,
			},
			{
				path: 'courses',
				element: <CoursesView />,
			},
			{
				path: 'subjects',
				element: <SubjectsView />,
			},
			{
				path: 'skills',
				element: <SkillsView />,
			},
			{
				path: 'axis',
				element: <AxisView />,
			},
			{
				path: 'objectives',
				element: <ObjectivesView />,
			},
			{
				path: 'levels',
				element: <LevelsView />,
			},
			{
				path: 'content-groups',
				element: <ContentGroupsView />,
			},
			{
				path: 'relation-maker',
				element: <RelationMakerView />,
			},
			{
				path: 'categories',
				errorElement: <ErrorBoundary />,
				children: [
					{
						index: true,
						element: <CategoryView />,
					},
				],
			},
			{
				path: 'books',
				errorElement: <ErrorBoundary />,
				children: [
					{
						index: true,
						element: <BooksView />,
					},
					{
						path: 'info/:id',
						element: <InfoBookView />,
					},
					{
						path: 'create',
						children: [
							{
								index: true,
								element: <CreateBooksView />,
							},
						],
					},
					{
						path: 'edit/:id',
						element: <EditBookView />,
					},
				],
			},
			{
				path: 'profile',
				element: <ProfileView />,
			},
		],
	},
	{
		path: '*',
		element: <DashboardLayout />,
		children: [
			{
				index: true,
				element: <HomeView />,
			},
		],
	},
];
