import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import _ from 'lodash';
import { Suspense, useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { CustomAlert } from '../../components/custom-alert';
import { LoadingPage } from '../../components/loading-page';
import { useAlert } from '../../hooks/useAlert';
import { useApp } from '../../hooks/useApp';
import { useAuth } from '../../hooks/useAuth';
import { useUser } from '../../hooks/useUser';
import { toTop } from '../../utils/common';
import { Header } from './components/header';
import { Navigation } from './components/navigation';

const Root = styled('div')(() => ({
	clipPath: 'inset(0)',
	maxWidth: '100%',
	margin: '0 auto',
	boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
	'& .container': {
		maxWidth: '100%',
		width: '100%',
		margin: '0 auto',
	},
}));

const DashboardLayout = () => {
	const { user } = useUser();
	const { handleLogout } = useAuth();
	const { loading, showNav } = useApp();
	const { handleShowAlert } = useAlert();
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (_.isEmpty(user)) {
			navigate('/sign-in');
		}
	}, [user]);

	useEffect(() => {
		window.addEventListener('logout', () => {
			setTimeout(() => {
				handleLogout();
			}, 5000);

			handleShowAlert({
				message: 'Tu sesión ha expirado, serás redirigido al inicio de sesión en unos segundos.',
				type: 'error',
			});
		});

		return () => {
			window.removeEventListener('logout', () => {});
		};
	}, []);

	useEffect(() => {
		toTop();
	}, [location]);

	return (
		<Root className="flex w-full">
			<div className="flex min-w-0 flex-auto">
				{showNav && <Navigation />}

				{loading && (
					<Box className=" top-0 fixed w-screen h-screen flex items-center justify-center bg-black bg-opacity-50 z-9999">
						<LoadingPage />
					</Box>
				)}

				<main className="relative z-10 flex min-h-full min-w-0 flex-auto flex-col">
					<Header />
					<CustomAlert />
					<div className="relative z-10 flex min-h-0 flex-auto flex-col">
						<Suspense fallback={<LoadingPage />}>
							<Outlet />
						</Suspense>
					</div>
				</main>
			</div>
		</Root>
	);
};

export { DashboardLayout };
