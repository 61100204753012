import { ViewHeadline } from '@mui/icons-material';
import { Box, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { darkTheme } from '../../../../config/theme';
import { useApp } from '../../../../hooks/useApp';
import { useUser } from '../../../../hooks/useUser';
import { rolesMap, UserInfo } from '../../../../interfaces/user';
import { navigation } from '../../../../routes/navigation';

const StyledNavBar = styled('div')(({ theme }) => ({
	minWidth: 280,
	width: 280,
	maxWidth: 280,
	transition: theme.transitions.create('margin', {
		easing: theme.transitions.easing.easeOut,
		duration: theme.transitions.duration.enteringScreen,
	}),
}));

const Root = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	color: theme.palette.text.primary,
	'&::-webkit-scrollbar-thumb': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.24)' : 'rgba(255, 255, 255, 0.24)'
		}`,
	},
	'&::-webkit-scrollbar-thumb:active': {
		boxShadow: `inset 0 0 0 20px ${
			theme.palette.mode === 'light' ? 'rgba(0, 0, 0, 0.37)' : 'rgba(255, 255, 255, 0.37)'
		}`,
	},
}));

const Navigation = () => {
	const { user } = useUser();
	const { setShowNav } = useApp();
	const { pathname } = useLocation();
	const [itemActive, setItemActive] = useState<string>(pathname.substring(1));
	const resize = window.innerWidth >= 1200;

	useEffect(() => {
		setItemActive(pathname.substring(1));
	}, [pathname]);

	return (
		<ThemeProvider theme={darkTheme}>
			<StyledNavBar
				className={`${
					resize ? 'sticky h-screen' : 'absolute h-full'
				} top-0 z-20 flex-auto shrink-0 flex-col overflow-hidden shadow-5`}
			>
				<Root className="flex h-full flex-auto flex-col overflow-hidden">
					<Box className="flex min-h-0 flex-1 flex-col">
						<Box className="relative flex flex-col items-center justify-center p-16 pb-14 shadow-0">
							<IconButton
								className="absolute h-40 w-40 p-0 top-10 right-10"
								size="large"
								onClick={() => setShowNav(false)}
							>
								<ViewHeadline />
							</IconButton>

							<Box className="mb-24 flex items-center justify-center pt-20">
								<Box className="w-3/5">
									<img src="/images/logo.svg" alt="logo" />
								</Box>
							</Box>

							<Typography className="whitespace-nowrap text-14 font-medium">
								{user?.name} {user?.firstSurname} {user?.secondSurname || ''}
							</Typography>

							<Typography className="whitespace-nowrap text-13 font-medium" color="text.secondary">
								{rolesMap.get(user?.role as string)}
							</Typography>
						</Box>

						<List disablePadding className="overflow-y-auto overflow-x-hidden pb-112">
							{navigation.map(({ id, title, subtitle, children, show, rolesAllowed }, i) => {
								const showItem = typeof show === 'function' ? show(user as UserInfo) : show;
								const allowByRole = rolesAllowed?.find((role) => role === user?.role);

								return allowByRole && showItem ? (
									<motion.div
										key={id}
										initial={{ y: 20, opacity: 0 }}
										animate={{ y: 0, opacity: 1, transition: { delay: 0.2 * i } }}
									>
										<Box className="mt-16">
											<Box className="px-16">
												<Typography variant="body1" component="p" className="whitespace-nowrap text-14 font-medium">
													{title}
												</Typography>

												{subtitle && (
													<Typography
														variant="body2"
														component="span"
														className="whitespace-nowrap text-12 font-medium"
														color="text.secondary"
													>
														{subtitle}
													</Typography>
												)}
											</Box>

											{children.map(({ id, title, icon, to, rolesAllowed, show }) => {
												const Icon = icon;
												const active = itemActive === to.substring(1);
												const allowByRole = rolesAllowed?.find((role) => role === user?.role);

												return allowByRole && show ? (
													<ListItem disablePadding key={id} className="px-16 w-full">
														<ListItemButton
															selected={active}
															component={Link}
															to={to}
															className={`rounded-lg mb-4 ${active ? '!bg-white/10' : ''}`}
														>
															<ListItemIcon className="min-w-[35px]">{<Icon />}</ListItemIcon>
															<ListItemText>{title}</ListItemText>
														</ListItemButton>
													</ListItem>
												) : null;
											})}
										</Box>
									</motion.div>
								) : null;
							})}
						</List>
					</Box>
				</Root>
			</StyledNavBar>
		</ThemeProvider>
	);
};

export { Navigation };
