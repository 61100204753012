import * as yup from 'yup';

import { LOCALE_ALLOWED } from '../interfaces/book';
import { COURSE_TYPE_ALLOWED } from '../interfaces/course';
import { SEX_ALLOWED } from '../interfaces/student';
import { ROLES_ALLOWED } from '../interfaces/user';

export const singInSchema = yup.object().shape({
	email: yup
		.string()
		.email('Debes ingresar un correo electrónico válido')
		.required('El correo electrónico es requerido'),
	password: yup.string().required('Ingresa tu contraseña').min(4, 'La contraseña debe tener al menos 4 caracteres'),
	remember: yup.boolean(),
});

export const recoveryPasswordSchema = yup.object().shape({
	email: yup
		.string()
		.email('Debes ingresar un correo electrónico válido')
		.required('El correo electrónico es requerido'),
});

export const newUserSchema = yup.object().shape({
	identification: yup.string().notRequired(),
	name: yup.string().required('El nombre es requerido'),
	firstSurname: yup.string().required('El apellido paterno es requerido'),
	secondSurname: yup.string().notRequired(),
	email: yup.string().email('El correo electrónico no es válido').required('El correo electrónico es requerido'),
	phone: yup.string().notRequired(),
	role: yup
		.string()
		.required('El rol es requerido')
		.oneOf(
			[
				ROLES_ALLOWED.SUPER_ADMIN,
				ROLES_ALLOWED.ADMIN,
				ROLES_ALLOWED.SCHOOL_MANAGER,
				ROLES_ALLOWED.TEACHER,
				ROLES_ALLOWED.PARENT,
			],
			'Se debe seleccionar un rol',
		),
});

export const newProfileSchema = yup.object().shape({
	identification: yup.string().notRequired(),
	name: yup.string().required('El nombre es requerido'),
	firstSurname: yup.string().required('El apellido paterno es requerido'),
	secondSurname: yup.string().notRequired(),
	email: yup.string().notRequired().email('El correo electrónico no es válido'),
	sex: yup
		.string()
		.required('El sexo es requerido')
		.oneOf([SEX_ALLOWED.FEMALE, SEX_ALLOWED.MALE, SEX_ALLOWED.OTHER], 'Se debe seleccionar un rol'),
});

export const newSchoolSchema = yup.object().shape({
	identification: yup.string(),
	name: yup.string().required('El nombre es requerido'),
	direction: yup.string().required('La dirección es requerida'),
	startDate: yup.date().typeError('La fecha de inicio es requerida').required().nullable(),
	endDate: yup.date().typeError('La fecha de finalización es requerida').required().nullable(),
	contentGroup: yup
		.array()
		.required('El grupo de contenido es requerido')
		.min(1, 'Debes seleccionar al menos un grupo de contenido'),
	managers: yup
		.array()
		.required('El administrador del colegio es requerido')
		.min(1, 'Debes seleccionar al menos un administrador del colegio'),
});

export const newCourseSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
	section: yup.string().required('La sección es requerida'),
	levelID: yup.string().required('El nivel es requerido'),
	type: yup
		.string()
		.required('El tipo es requerido')
		.oneOf([COURSE_TYPE_ALLOWED.INFANT, COURSE_TYPE_ALLOWED.BASIC], 'Se debe seleccionar un tipo'),
});

export const newSubjectSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
	country: yup.string().required('El país es requerido'),
	axisID: yup.array().required('Los ejes son requeridos').min(1, 'Debes seleccionar al menos un eje'),
	skillsID: yup.array().required('Las habilidades son requeridas').min(1, 'Debes seleccionar al menos una habilidad'),
});

export const newSkillSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
});

export const newLevelSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
});

export const newContentGroupSchema = yup.object().shape({
	id: yup.string().required('El id es requerido'),
	name: yup.string().required('El nombre es requerido'),
});

export const newAxiSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
	objectivesID: yup
		.array()
		.required('El objetivo de aprendizaje es requerido')
		.min(1, 'Debes seleccionar al menos un objetivo de aprendizaje'),
});

export const newObjectiveSchema = yup.object().shape({
	code: yup.string().required('El código es requerido'),
	name: yup.string().required('El nombre es requerido'),
});

export const newCategorySchema = yup.object().shape({
	id: yup.string().required('El id es requerido'),
	name_es: yup.string().required('El nombre en español es requerido'),
	name_en: yup.string().required('El nombre en inglés es requerido'),
	name_pt: yup.string().required('El nombre en portugués es requerido'),
});

export const newBookSchema = yup.object().shape({
	name: yup.string().required('El título es requerido'),
	description: yup.string().required('La descripción es requerida'),
	locale: yup
		.string()
		.required('El tipo es requerido')
		.oneOf([LOCALE_ALLOWED.EN, LOCALE_ALLOWED.ES, LOCALE_ALLOWED.PT], 'Se debe seleccionar un lenguaje'),
	contentGroupID: yup
		.array()
		.of(yup.string())
		.required('El grupo de contenido es requerido')
		.min(1, 'Debes seleccionar al menos un grupo de contenido'),
	levelID: yup.array().required('El nivel es requerido').min(1, 'Debes seleccionar al menos un nivel'),
	categoryID: yup.array().required('La categoría es requerida').min(1, 'Debes seleccionar al menos una categoría'),
	author: yup.string().required('El autor es requerido'),
	editor: yup.string().required('El editor es requerido'),
	composer: yup.string().required('El compositor es requerido'),
	illustrator: yup.string().required('El ilustrador es requerido'),
	storyteller: yup.string().required('El narrador es requerido'),
	featured: yup.boolean().required('El destacado es requerido'),
	image: yup.mixed().required('La imagen es requerida'),
	audio: yup.mixed(),
});
