import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SchoolState } from '../../interfaces/school';

const initialState: SchoolState = {
	info: undefined,
};

export const schoolSlice = createSlice({
	name: 'school',
	initialState,
	reducers: {
		setSchoolInfo: (state, action: PayloadAction<SchoolState>) => {
			state.info = action.payload.info;
		},
		clearSchoolInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setSchoolInfo, clearSchoolInfo } = schoolSlice.actions;
export default schoolSlice.reducer;
