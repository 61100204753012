/* eslint-disable no-useless-catch */
import { AuthServices, LoginParams } from '../services/auth';
import { clearCategoryInfo } from '../store/category';
import { clearContentGroupInfo } from '../store/content-groups';
import { useAppDispatch } from '../store/hooks';
import { clearUserInfo } from '../store/user';

const useAuth = () => {
	const dispatch = useAppDispatch();

	const handleLogin = async ({ email, password }: LoginParams) => {
		const response = await AuthServices.login({ email, password });
		localStorage.setItem('token', response?.token as string);
	};

	const handleRecoveryPassword = async (email: string) => {
		try {
			await AuthServices.recoveryPassword(email);
		} catch (error) {
			throw error;
		}
	};

	const handleLogout = () => {
		localStorage.removeItem('token');
		dispatch(clearUserInfo());
		dispatch(clearCategoryInfo());
		dispatch(clearContentGroupInfo());
	};

	return { handleLogin, handleRecoveryPassword, handleLogout };
};

export { useAuth };
