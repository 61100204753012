import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AxiState } from '../../interfaces/axi';

const initialState: AxiState = {
	info: undefined,
};

export const axiSlice = createSlice({
	name: 'axi',
	initialState,
	reducers: {
		setAxiInfo: (state, action: PayloadAction<AxiState>) => {
			state.info = action.payload.info;
		},
		clearAxiInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setAxiInfo, clearAxiInfo } = axiSlice.actions;
export default axiSlice.reducer;
