import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInfo, UserState } from '../../interfaces/user';

const initialState: UserState = {
	info: undefined,
	users: undefined,
};

export const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUserInfo: (state, action: PayloadAction<UserInfo>) => {
			state.info = action.payload;
		},
		setUserList: (state, action: PayloadAction<UserInfo[]>) => {
			state.users = action.payload;
		},
		clearUserInfo: (state) => {
			state.info = undefined;
		},
	},
});

export const { setUserInfo, setUserList, clearUserInfo } = userSlice.actions;
export default userSlice.reducer;
