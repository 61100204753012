import { ClientResponse } from '../interfaces/common';
import { UserInfo } from '../interfaces/user';
import { put } from '../utils/api';

const modifyShortcuts = async (shortcuts: string[]) => {
	const { data } = await put<ClientResponse<UserInfo>>('/shortcuts/toggle', { shortcuts });
	return data;
};

export const ShortcutsServices = {
	modifyShortcuts,
};
