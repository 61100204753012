import axios from 'axios';

import { CustomError } from '../interfaces/common';

export type ErrorType =
	| 'system/default'
	| 'system/contact-support-area'
	| 'system/route-need-auth'
	| 'system/bad-request'
	| 'system/internal-server-error'
	| 'system/token-expired'
	| 'system/unauthorized'
	| 'system/not-allowed'
	| 'auth/email-or-password-error'
	| 'auth/email-already-exist'
	| 'auth/email-not-found'
	| 'auth/login-failed'
	| 'user/user-failed'
	| 'user/user-list-failed'
	| 'user/create-user-failed'
	| 'user/update-user-failed'
	| 'user/disable-user-failed'
	| 'school/school-failed'
	| 'school/schools-failed'
	| 'school/at-least-one-subject';

const errorCodes = new Map<ErrorType, string>([
	['system/default', 'Ha ocurrido un error inesperado. Intenta nuevamente'],
	['system/contact-support-area', 'Contacta con al área de soporte'],
	['system/route-need-auth', 'Necesitas iniciar sesión para acceder a esta ruta'],
	['system/bad-request', 'La solicitud no se pudo procesar'],
	['system/internal-server-error', 'Ha ocurrido un error interno. Intenta nuevamente'],
	['system/token-expired', 'La sesión ha expirado'],
	['system/unauthorized', 'No tienes permisos para realizar esta acción'],
	['system/not-allowed', 'No tienes permitido ingresar a esta área. Contacta con el área de soporte'],
	['auth/email-or-password-error', 'El correo o la contraseña son incorrectos'],
	['auth/email-already-exist', 'El correo ya está registrado'],
	['auth/email-not-found', 'El correo no está registrado'],
	['auth/login-failed', 'No se pudo iniciar sesión'],
	['user/user-failed', 'No se pudo obtener el usuario'],
	['user/user-list-failed', 'No se pudo obtener la lista de usuarios'],
	['user/create-user-failed', 'No se pudo crear el usuario'],
	['user/update-user-failed', 'No se pudo actualizar el usuario'],
	['user/disable-user-failed', 'No se pudo deshabilitar el usuario'],
	['school/school-failed', 'No se pudo obtener la escuela'],
	['school/schools-failed', 'No se pudo obtener la lista de escuelas'],
	['school/at-least-one-subject', 'El curso no se puede quedar sin asignaturas'],
]);

export const errorManager = (error: unknown) => {
	if (axios.isAxiosError<CustomError>(error)) {
		const errorCode = error.response?.data.message || error.response?.data.code;
		const errorTransformed = errorCodes.get(errorCode as ErrorType);
		return errorTransformed || errorCodes.get('system/default');
	} else {
		return errorCodes.get('system/default');
	}
};
