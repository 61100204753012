import { Action, combineReducers, configureStore, Middleware, ThunkAction } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunkMiddleware from 'redux-thunk';

import axiReducer from './axi';
import categoryReducer from './category';
import contentGroupReducer from './content-groups';
import levelReducer from './level';
import objectiveReducer from './objective';
import schoolReducer from './school';
import skillReducer from './skill';
import userReducer from './user';

const middlewares: Middleware[] = [thunkMiddleware];

if (import.meta.env.DEV) {
	const logger = createLogger({ collapsed: (_getState, _action, logEntry) => (logEntry ? !logEntry.error : true) });
	middlewares.push(logger);
}

const persistConfig = {
	key: 'root',
	storage,
	withlist: ['user', 'axi', 'category', 'content-group', 'objective', 'school', 'level', 'skill'],
};

const rootReducer = combineReducers({
	user: userReducer,
	axi: axiReducer,
	category: categoryReducer,
	contentGroup: contentGroupReducer,
	objective: objectiveReducer,
	school: schoolReducer,
	skill: skillReducer,
	levels: levelReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: false,
			inmutableCheck: false,
		}).concat(middlewares),
	devTools: import.meta.env.DEV,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
